import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Dashboard from '../pages/Dashboard';
import Repository from '../pages/Repository';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import  Profile  from '../pages/Profile';


const Routes: React.FC = () => (
  <Switch>
    <Route path='/' exact component={SignIn} ></Route>
    <Route path='/signup' component={SignUp} ></Route>
    <Route path='/forgot-password' component={ForgotPassword} ></Route>
    <Route path='/reset-password' component={ResetPassword} ></Route>

    <Route path='/dashboard' component={Dashboard} isPrivate></Route>
    <Route path='/repository' component={Repository} isPrivate></Route>
    <Route path='/profile' component={Profile} isPrivate></Route>

  </Switch>
);
export default Routes;
