import React, { ChangeEvent, useCallback, useRef } from 'react';
import {
   FiMail, FiLock, FiUser, FiCamera, FiArrowLeft,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {
  Container, Content,AvatarInput,
} from './style';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';

interface ProfileFormData{
  name: string;
  email:string;
  old_password:string;
  password:string;
  password_confirmation:string;
}
const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const {user, updateUser} = useAuth();
  const handleAvatarChange = useCallback(
    ( e: ChangeEvent<HTMLInputElement>) => {
      if(e.target.files){
        const data = new FormData();
        data.append('avatar', e.target.files[0]);

        api.patch('/users/avatar', data).then(response =>{
          updateUser(response.data);
          addToast({
            type: 'success',
            title: 'Avatar atualizado!'
          })
        });
      }

    },
    [addToast, updateUser],
  );
  const handleSubmit = useCallback(async (data:ProfileFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string().required('E-mail obrigatório').email('Digite um E-mail válido'),
        old_password: Yup.string(),
        password: Yup.string().when('old_password', {
          is: val => !! val.length,
          then: Yup.string().required('Campo obrigatório!').min(8, 'No mínimo 8 digitos'),
          otherwise: Yup.string(),
        }),

        password_confirmation: Yup.string().when('password', {
          is: val => !! val.length,
          then: Yup.string().required('Campo obrigatório').min(8, 'No mínimo 8 digitos'),
          otherwise: Yup.string(),
        }).oneOf([Yup.ref('password'), ''], 'Confirmação incorreta!'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const {name, email, old_password, password, password_confirmation} = data;
      const formData = {name, email, ...(old_password ?{ old_password, password, password_confirmation} : {}),};
      const response =  await api.put('/profile', formData);
      updateUser(response.data);

      history.push('/dashboard');
      addToast({
        type: 'success',
        title: 'Perfil atualizado',
        description: 'Suas informações foram atualizadas com sucesso!',

      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErrors(err);
        formRef.current?.setErrors(erros);
      } else {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro atualização do dados do perfil.',
        });
      }
    }
  }, [addToast, history, updateUser]);
  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft/>
          </Link>
        </div>
      </header>

    <Content>
        <Form ref={formRef} initialData={{name: user.name, email: user.email}} onSubmit={handleSubmit}>
          <AvatarInput>
            <img src={user.avatar_url} alt={user.name}/>
            <label htmlFor="avatar">
              <FiCamera/>
              <input type="file" id="avatar" onChange={handleAvatarChange}></input>
            </label>
          </AvatarInput>
          <h1>Meu perfil</h1>

          <Input name="name" icon={FiUser} placeholder="Nome"/>

          <Input name="email" icon={FiMail} placeholder="E-mail"/>

          <Input name="old_password" icon={FiLock} type="password" placeholder="Password" contanerStyle={{marginTop:24}}/>
          <Input name="password" icon={FiLock} type="password" placeholder="New password"/>
          <Input name="password_confirmation" icon={FiLock} type="password" placeholder="Password confirmation"/>

          <Button type="submit">Confirmar mudanças</Button>

        </Form>

    </Content>
  </Container>
  );
};

export default Profile;
