import React, { useCallback, useRef } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import logoImg from '../../assets/Logo.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {
  Container, Content, Background, AnimationContainer,
} from './style';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface ResetPasswordFormData{
  password:string,
  password_confirmation:string,
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(async (data: ResetPasswordFormData) => {
    try {
    formRef.current?.setErrors({});
    const schema = Yup.object().shape({
      password: Yup.string().required('Digite sua senha'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), ''], 'A senha informada não é igual a anteriormente digitada'),
    });
    await schema.validate(data, {
      abortEarly: false,
    });
    const { password, password_confirmation } = data;
    const token = location.search.replace("?token=", "");

    if (!token) {
      throw new Error();
    }
    await api.post('/password/reset', {
      password,
      password_confirmation,
      token,
    });

    history.push('/');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErrors(err);
        formRef.current?.setErrors(erros);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no reset da senha',
          description: 'Ocorreu um erro ao criar uma nova senha.',
        });
      }
    }
  }, [location,addToast, history],);

  return (
    <Container>
      <Content>
        <AnimationContainer>
            <img src={logoImg} alt="GoBarber"/>
            <Form ref={formRef} onSubmit={handleSubmit}>

              <h1>Reset login</h1>
              <Input name="password" icon={FiLock} type="password" placeholder="Nova senha"/>
              <Input name="password_confirmation" icon={FiLock} type="password" placeholder="Confirmação da senha"/>

              <Button type="submit">Alterar Senha</Button>

            </Form>

          </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ResetPassword;
