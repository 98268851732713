import React, { useCallback, useRef, useState } from 'react';
import { FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import logoImg from '../../assets/Logo.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {
  Container, Content, Background, AnimationContainer,
} from './style';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface ForgotPasswordFormData{
  email:string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: ForgotPasswordFormData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required('Digite seu E-mail').email('Digite um email válido!'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await api.post('/password/forgot', {
        email: data.email,
      });
      addToast({
        type: 'success',
        title: 'E-mail de recuperação enviado',
        description: 'Foi enviado um email para a recuperação de senha, favor verificar sua caixa de entrada',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErrors(err);
        formRef.current?.setErrors(erros);
      } else {
        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha',
          description: 'Ocorreu um erro ao fazer a ação de recuperação de senha do usuário.',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
            <img src={logoImg} alt="GoBarber"/>
            <Form ref={formRef} onSubmit={handleSubmit}>

              <h1>Recuperar de Senha</h1>

              <Input name="email" icon={FiMail} placeholder="E-mail"/>
              <Button loading={loading} type="submit">Recuperar</Button>
            </Form>

            <Link to="/">
            <FiArrowLeft/>
            Voltar para logon
          </Link>
          </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ForgotPassword;
